"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PreviousBtn = void 0;
var PreviousBtn = /** @class */ (function () {
    function PreviousBtn() {
    }
    PreviousBtn.prototype.bindPreviousButton = function () {
        var _this = this;
        var _a;
        this.button = document.querySelector('button[name$="[previousButton]"]');
        (_a = this.button) === null || _a === void 0 ? void 0 : _a.addEventListener('click', function () {
            var _a;
            window.location.href = (_a = _this.button) === null || _a === void 0 ? void 0 : _a.getAttribute('data-target');
        });
    };
    return PreviousBtn;
}());
exports.PreviousBtn = PreviousBtn;
